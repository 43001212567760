import { detect } from "detect-browser";
import { Page } from "gatsby";
import React, { FC, useMemo } from "react";
import tw, { styled } from "twin.macro";

import Layout from "@/components/Layout";
import Logo from "@/components/Logo";
import Wordmark from "@/components/Wordmark";

import previewImage from "../assets/images/preview.png";

const Wrapper = styled(Layout)`
${tw`flex flex-col`}
`;

const Inner = styled.div`
${tw`flex gap-8 m-auto max-w-screen-xl p-8 w-full`}
`;

const Body = styled.div`
  ${tw`flex flex-1 flex-col items-center self-center md:items-start`}
`;

const FullLogo = styled.div`
  ${tw`grid gap-4 items-center mb-8`}
`;

const StyledLogo = styled(Logo)`
  ${tw`w-24`}
`;

const StyledWordmark = styled(Wordmark)`
  ${tw`w-72`}
`;

const Description = styled.div`
  ${tw`font-medium leading-tight mb-8 text-white/50 text-2xl text-center md:text-left`}
`;

const InstallButton = styled.a`
  ${tw`bg-purple-500 flex font-bold gap-4 items-center leading-tight px-8 py-4 rounded text-center text-xl transition hover:bg-purple-400 active:bg-purple-600`}

  svg {
    ${tw`fill-current w-8`}
  }

  img {
    ${tw`-my-4 w-8`}
  }
`;

const Preview = styled.div`
  ${tw`flex-1 hidden md:(flex items-center justify-center)`}

  max-width: 420px;
`;

const Footer = styled.div`
  ${tw`flex flex-wrap gap-12 justify-center px-8 py-4`}

  a {
    ${tw`text-white/50 hover:(text-white underline)`}
  }
`;

const IndexPage: FC<Page> = () => {
  const installProps = useMemo(() => {
    const browser = detect();

    switch (browser?.name) {
      case "firefox":
        return {
          url: "https://addons.mozilla.org/firefox/addon/gumbo-twitch-companion",
          title: "Firefox Add-ons",
          icon: (
            <svg viewBox="0 0 24 24">
              <path d="M8.824 7.287c.008 0 .004 0 0 0zm-2.8-1.4c.006 0 .003 0 0 0zm16.754 2.161c-.505-1.215-1.53-2.528-2.333-2.943.654 1.283 1.033 2.57 1.177 3.53l.002.02c-1.314-3.278-3.544-4.6-5.366-7.477-.091-.147-.184-.292-.273-.446a3.545 3.545 0 01-.13-.24 2.118 2.118 0 01-.172-.46.03.03 0 00-.027-.03.038.038 0 00-.021 0l-.006.001a.037.037 0 00-.01.005L15.624 0c-2.585 1.515-3.657 4.168-3.932 5.856a6.197 6.197 0 00-2.305.587.297.297 0 00-.147.37c.057.162.24.24.396.17a5.622 5.622 0 012.008-.523l.067-.005a5.847 5.847 0 011.957.222l.095.03a5.816 5.816 0 01.616.228c.08.036.16.073.238.112l.107.055a5.835 5.835 0 01.368.211 5.953 5.953 0 012.034 2.104c-.62-.437-1.733-.868-2.803-.681 4.183 2.09 3.06 9.292-2.737 9.02a5.164 5.164 0 01-1.513-.292 4.42 4.42 0 01-.538-.232c-1.42-.735-2.593-2.121-2.74-3.806 0 0 .537-2 3.845-2 .357 0 1.38-.998 1.398-1.287-.005-.095-2.029-.9-2.817-1.677-.422-.416-.622-.616-.8-.767a3.47 3.47 0 00-.301-.227 5.388 5.388 0 01-.032-2.842c-1.195.544-2.124 1.403-2.8 2.163h-.006c-.46-.584-.428-2.51-.402-2.913-.006-.025-.343.176-.389.206-.406.29-.787.616-1.136.974-.397.403-.76.839-1.085 1.303a9.816 9.816 0 00-1.562 3.52c-.003.013-.11.487-.19 1.073-.013.09-.026.181-.037.272a7.8 7.8 0 00-.069.667l-.002.034-.023.387-.001.06C.386 18.795 5.593 24 12.016 24c5.752 0 10.527-4.176 11.463-9.661.02-.149.035-.298.052-.448.232-1.994-.025-4.09-.753-5.844z" />
            </svg>
          ),
        };
    }

    return {
      url: "https://chrome.google.com/webstore/detail/gumbo-twitch-companion/aalmjfpohaedoddkobnibokclgeefamn",
      title: "Chrome Web Store",
      icon: (
        <svg viewBox="0 0 24 24">
          <path d="M12 0C8.21 0 4.831 1.757 2.632 4.501l3.953 6.848A5.454 5.454 0 0 1 12 6.545h10.691A12 12 0 0 0 12 0zM1.931 5.47A11.943 11.943 0 0 0 0 12c0 6.012 4.42 10.991 10.189 11.864l3.953-6.847a5.45 5.45 0 0 1-6.865-2.29zm13.342 2.166a5.446 5.446 0 0 1 1.45 7.09l.002.001h-.002l-5.344 9.257c.206.01.413.016.621.016 6.627 0 12-5.373 12-12 0-1.54-.29-3.011-.818-4.364zM12 16.364a4.364 4.364 0 1 1 0-8.728 4.364 4.364 0 0 1 0 8.728Z" />
        </svg>
      ),
    };
  }, []);

  return (
    <Wrapper>
      <Inner>
        <Body>
          <FullLogo>
            <StyledLogo />
            <StyledWordmark />
          </FullLogo>

          <Description>
            Swollen companion keeping you updated on your Twitch experience
          </Description>

          <InstallButton href={installProps.url} rel="noopener noreferrer" target="_blank">
            {installProps.icon}
            <span>Install from {installProps.title}</span>
          </InstallButton>
        </Body>
        <Preview>
          <img src={previewImage} />
        </Preview>
      </Inner>
      <Footer>
        <a href="https://github.com/seldszar/gumbo" rel="noopener noreferrer" target="_blank">
          Source Code
        </a>
        <a href="https://github.com/seldszar/gumbo/issue" rel="noopener noreferrer" target="_blank">
          Bugs &amp; Suggestions
        </a>
        <a href="https://github.com/seldszar/gumbo/releases" rel="noopener noreferrer" target="_blank">
          Release Notes
        </a>
      </Footer>
    </Wrapper>
  );
};

export default IndexPage;
